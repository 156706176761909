import { motion } from "framer-motion";

const easing = [0.6, -0.05, 0.01, 0.99];
const animation = {
  initial: { y: 100, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.34, eas: easing },
  },
};

const NoBoardFound = () => (
  <motion.div
    exit={{ opacity: 0 }}
    initial="initial"
    animate="animate"
    variants={animation}
    className="p-5"
  >
    <div
      className="img-wrapper text-center"
      style={{ width: 150, margin: "auto" }}
    >
      <img src="/icons/no-item-found.svg" className={"resp-img"} />
    </div>
    <p className="mt-4 text-secondary text-center">
      Pas de panneau de ce type disponible actuellement
    </p>
  </motion.div>
);

export default NoBoardFound;
