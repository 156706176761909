import Navbar from "../navbar/Navbar";

const Header = ({ children }) => {
  return (
    <header>
      <Navbar />
      {children}
    </header>
  );
};

export default Header;
