import { useStoreState } from "easy-peasy";
import { RegitorrModal } from "./RegitorrModal";
import { FaShoppingCart } from "react-icons/fa";
import { motion } from "framer-motion";
import { Fragment, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import formatDatePicker from "../../utils/formatDatePicker";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

import dynamic from "next/dynamic";
const MapViewDetail = dynamic(
  () => import("../../components/billboards/MapViewDetail"),
  {
    ssr: false,
    //loading: () => <p>A map is loading.</p>
  }
);

const easing = [0.6, -0.05, 0.01, 0.99];
const animation = {
  initial: { x: 10, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.9, eas: easing },
  },
};

const QuickViewBillBoardModal = ({
  ModalWrapper,
  close,
  isOpen,
  handleClick,
  notAnAdvertiser,
  isNotConnected,
  myRef,
  executeScroll,
  mapView,
}) => {
  const { view, formatPrice } = useStoreState((state) => state.billboards);
  const { cartExist, getDaysArray } = useStoreState((state) => state.bookings);
  const { currentUser } = useStoreState((state) => state.auth);

  //DatePicker
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [inputs, setInputs] = useState({
    faces: "",
  });
  const [inputs2, setInputs2] = useState({
    faces: "Full",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  
  //date déjà prises
  let dateAlreadyTaken = [];

  dateAlreadyTaken =
    view !== 0 &&
    view.bookings
      .filter(
        (filtre) =>
          filtre.faces == inputs.faces && filtre.available == "En cours"
          ||
          filtre.faces == inputs.faces && filtre.available == "Validé"  
          || 
          filtre.faces == "Full" && filtre.available == "En cours" 
          ||
          filtre.faces == "Full" && filtre.available == "Validé"
      )
      .map((date) => [date.start_at, date.end_at]);

  if (!dateAlreadyTaken) {
    dateAlreadyTaken = [];
  }
 // console.log(view.bookings)

  const blockedDays = [];

  //Récupérer les sous dates du tablo dateAlreadyTaken
  dateAlreadyTaken?.forEach((element, i) => {
    const days = getDaysArray(new Date(element[0]), new Date(element[1]));

    blockedDays.push(...days);
  });

  //const dayList1 = getDaysArray(new Date("2021-07-04"), new Date("2021-07-06"));
  const dayList = [/*...dayList1,*/ ...blockedDays];
  
  return (
    <RegitorrModal
      ModalWrapper={ModalWrapper}
      title={
        <h3
          className="title fw-bold quickviewModalTitle"
          style={{
            marginTop: "10px",
          }}
        >
          <span>{view.location_detail}</span>
        </h3>
      }
      close={close}
      isOpen={isOpen}
    >
      <div className="pb-3 contenu">
        <div className="container">
          <div className="row">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="col-md-6"
            >
              <div className="boardImgWrap">
                <img
                  src={view !== 0 && view.image_url && view.image_url}
                  alt="l'image du panneau"
                  title="panneau"
                />
              </div>
            </motion.div>

            <motion.div
              className="col-md-6"
              exit={{ opacity: 0 }}
              initial="initial"
              animate="animate"
              variants={animation}
            >
              <div className="boardProps">
                <div className="prop-line d-flex justify-content-between">
                  <div className="propTitle">Région</div>
                  <span className="propVal">{view.region}</span>
                </div>

                <div className="prop-line d-flex justify-content-between">
                  <div className="propTitle">Commune</div>
                  <span className="propVal">{view.common}</span>
                </div>

                <div className="prop-line d-flex justify-content-between">
                  <div className="propTitle">Catégorie</div>
                  <span className="propVal">{view.category}</span>
                </div>

                <div className="prop-line d-flex justify-content-between">
                  <div className="propTitle">Dimensions</div>
                  <span className="propVal">{view.dimension}m²</span>
                </div>

                <div className="prop-line d-flex justify-content-between">
                  <div className="propTitle">
                    Prix {" "}  
                    {
                      view.bookend_by_face == true && " d'une face "
                    }
                    {
                      view.bookend_by_face == false && view.category == "Double face"
                      &&
                      " des 2 faces "
                    }
                    {
                      view.bookend_by_face == false && view.category == "Triple face"
                      &&
                      " des 3 faces " 
                    }
                    / mois
                  </div>
                  <span className="propVal">{formatPrice(view.price)}</span>
                </div>
              </div>

              <div className="space-ten"></div>
              <p className="description">{view.description}</p>
            </motion.div>
          </div>
        </div>

        <div className="container my-4">
          <div className="row">
            <div className="col-12">
              <div className="dashed-devider"></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mapview-n-book">
            <div className="col-md-6">
              <MapViewDetail
                view={view}
                myRef={myRef}
                executeScroll={executeScroll}
                mapView={mapView}
              />
            </div>
            <div className="col-md-6">
              <div className="mb-4 prebooking-form mb-md-0 container-fluid">
                <div className="row">
                  <div className="py-1 text-center col-12 prebooking-title">
                    Réserver ce panneau
                  </div>
                </div>
                <div className="px-2 pt-4 row px-lg-5 pt-lg-5">
                  {view.bookend_by_face == true && (
                    <div className="col-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="faces"
                          onChange={handleInputChange}
                          required={true}
                          defaultValue={inputs.faces}
                        >
                          <option value="" disabled>
                            Face du panneau
                          </option>
                          <option value="Face A">Face A</option>
                          <option value="Face B">Face B</option>
                          {view.category == "Triple face" && (
                            <Fragment>
                              <option value="Face C">Face C</option>
                            </Fragment>
                          )}
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="form-group">
                      <DatePicker
                        className="form-control date-range-picker"
                        placeholderText="Période"
                        minDate={Date.now()}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        isClearable={true}
                        dateFormat="dd-MM-yyyy"
                        locale="fr"
                        excludeDates={
                          view !== 0 && view.bookings.length > 0 && dayList
                        }
                        monthsShown={2}
                        withPortal
                        disabled={view.bookend_by_face == true && Object.values(inputs).some((input) => !input)}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-2 mt-2 px-lg-5">
                  {
                    <button
                      onClick={() =>
                        currentUser !== null && currentUser.profile
                          ? currentUser.profile.profile_type == "Annonceur"
                            ? handleClick(
                                view.id,
                                view.price,
                                view.region,
                                view.image_url,
                                view.category,
                                view.dimension,
                                view.common,
                                view.location_detail,
                                view.user,
                                formatDatePicker(startDate),
                                formatDatePicker(endDate),
                                view.bookings,
                                //dayList,
                                view.bookend_by_face,

                                view.bookend_by_face == true 
                                ?
                                inputs.faces
                                :
                                inputs2.faces
                              )
                            : notAnAdvertiser()
                          : isNotConnected()
                      }
                      className={
                        !cartExist(view.id)
                          ? "btn btnPlein btn-block"
                          : "btn btnPlein btn-block"
                      }
                      disabled={
                        Object.values(dateRange).some((data) => !data) ||
                        (view.bookend_by_face == true &&
                          Object.values(inputs).some((input) => !input))
                      }
                      style={{
                        backgroundColor: "#FEA832",
                        borderColor: "#FEA832",
                      }}
                    >
                      {!cartExist(view.id) ? <FaShoppingCart /> : "✔"}
                      {!cartExist(view.id) ? " Ajouter" : " Ajouté"}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .contenu {
          max-height: 490px;
          /* height: calc(100vh - 2rem); */
          overflow-x: hidden;
          overflow-y: auto;
        }

        .boardImgWrap {
          //height: 300px;
          width: 100%;
        }

        .boardImgWrap img {
          width: 100%;
          max-height: 100%;
          border-radius: 0.25rem;
        }

        .boardProps {
          max-width: 74%;
        }

        .boardProps .prop-line {
          border-bottom: 1px solid #a8c4e570;
          margin-bottom: 15px;
          padding-bottom: 4px;
          /*margin-bottom: 12px;
          padding-bottom: 2px;*/
          align-items: flex-start;
        }

        .boardProps .prop-line .propTitle {
          font-weight: 600;
          line-height: 1;
        }

        .boardProps .prop-line .propVal {
          font-size: 0.9rem;
          line-height: 1;
        }

        .dashed-devider {
          border-bottom: 1px dashed #a8c4e5;
        }

        @media screen and (max-width: 767px) {
          .boardProps {
            margin-top: 20px;
            max-width: 100%;
          }

          .mapview-n-book {
            flex-direction: column-reverse;
          }
        }

        .prebooking-form {
          height: 300px;
          background: #f5f4f1;
          border-radius: 0.25rem;
        }

        .prebooking-title {
          border-bottom: 1px solid #a8c4e5;
          color: #1647fb;
          font-size: 1.35rem;
          font-weight: 600;
        }

        @media screen and (max-device-width: 480px) {
          .contenu {
            height: 400px;
          }

          .boardImgWrap {
            height: 235px;
          }
        }

        /* img {
          width: 90%;
          max-width: 500px;
          margin: 0 auto;
          display: block;
          border-radius: 4px 4px 0 0;
        } */
        @media all and (max-device-width: 480px) {
          /* img {
            width: 112%;
          } */
          .title {
            //margin-left: -22px;
          }
        }
        @media all and (max-device-width: 992px) {
          .downstairs {
            margin-top: 20px;
          }
        }
        .space-ten {
          padding: 10px 0;
        }
        .parent {
          margin-left: 15em;
          margin-top: -24px;
          margin-bottom: -37px;
        }
      `}</style>
    </RegitorrModal>
  );
};

export default QuickViewBillBoardModal;
