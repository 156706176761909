import React from "react";
import styles from "./filter.module.scss";

const Filter = ({ filterHandler, regie }) => {
  return (
    <>
      {regie && (
        <h2 className="text-center catalogue" style={{ margin: "2rem auto" }}>
          <span className="f-600">Catalogue :</span> {regie.toUpperCase()}
        </h2>
      )}
      <div className={`${styles.header} ${regie && "mt-0"}`}>
        <h1>Réservez vos panneaux partout en Guinée</h1>
        <hr />

        <div className={styles.categories}>
          <div className={`d-flex justify-content-between ${styles.catTitles}`}>
            <div className="flex-1 ml-md-4 d-inline-block">
              <h6>Catégories</h6>
            </div>
            <div className="flex-1 d-inline-block">
              <h6>Dimensions</h6>
            </div>
            <div className="flex-1 d-inline-block">
              <h6>Zones</h6>
            </div>
          </div>

          <div className="content">
            <div className="mt-2 d-flex justify-content-between">
              {/*Catégories*/}
              <div className="flex-1 ml-md-4">
                <div className="row sectionOne">
                  <div className="col-lg-4">
                    <div className={styles.checkbox}>
                      <input
                        type="checkbox"
                        data-filter-type="category"
                        id="simple"
                        name="simple face"
                        onClick={filterHandler}
                      />
                      <label htmlFor="simple">
                        <span>Simple</span>
                      </label>
                    </div>
                    <div className={styles.checkbox}>
                      <input
                        type="checkbox"
                        data-filter-type="category"
                        id="double"
                        name="double face"
                        onClick={filterHandler}
                      />
                      <label htmlFor="double">
                        <span>Double</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className={styles.checkbox}>
                      <input
                        type="checkbox"
                        data-filter-type="category"
                        id="triple"
                        name="triple face"
                        onClick={filterHandler}
                      />
                      <label htmlFor="triple">
                        <span>Triple</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/*Dimensions*/}
              <div className="flex-1">
                <div className="">
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="douze"
                      name="12"
                      onClick={filterHandler}
                    />
                    <label htmlFor="douze">
                      <span>12 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="dixhuit"
                      name="18"
                      onClick={filterHandler}
                    />
                    <label htmlFor="dixhuit">
                      <span>18 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="vingtUn"
                      name="21"
                      onClick={filterHandler}
                    />
                    <label htmlFor="vingtUn">
                      <span>21 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="trenteDeux"
                      name="32"
                      onClick={filterHandler}
                    />
                    <label htmlFor="trenteDeux">
                      <span>32 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="quarante"
                      name="40"
                      onClick={filterHandler}
                    />
                    <label htmlFor="quarante">
                      <span>40 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="cinquanteQuatre"
                      name="54"
                      onClick={filterHandler}
                    />
                    <label htmlFor="cinquanteQuatre">
                      <span>54 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="soixante"
                      name="60"
                      onClick={filterHandler}
                    />
                    <label htmlFor="soixante">
                      <span>60 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="soixanteDix"
                      name="70"
                      onClick={filterHandler}
                    />
                    <label htmlFor="soixanteDix">
                      <span>70 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="centQuarante"
                      name="140"
                      onClick={filterHandler}
                    />
                    <label htmlFor="centQuarante">
                      <span>140 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="centQuarantQuatre"
                      name="144"
                      onClick={filterHandler}
                    />
                    <label htmlFor="centQuarantQuatre">
                      <span>144 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="troisCentSoixante"
                      name="360"
                      onClick={filterHandler}
                    />
                    <label htmlFor="troisCentSoixante">
                      <span>360 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="quatreCent"
                      name="400"
                      onClick={filterHandler}
                    />
                    <label htmlFor="quatreCent">
                      <span>400 m²</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="dimension"
                      id="cinqCentDix"
                      name="510"
                      onClick={filterHandler}
                    />
                    <label htmlFor="cinqCentDix">
                      <span>510 m²</span>
                    </label>
                  </div>
                </div>
              </div>
              {/*Zones*/}
              <div className="flex-1">
                <div className="">
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="kaloum"
                      name="kaloum"
                      onClick={filterHandler}
                    />
                    <label htmlFor="kaloum">
                      <span>Kaloum</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="ratoma"
                      name="ratoma"
                      onClick={filterHandler}
                    />
                    <label htmlFor="ratoma">
                      <span>Ratoma</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="matoto"
                      name="matoto"
                      onClick={filterHandler}
                    />
                    <label htmlFor="matoto">
                      <span>Matoto</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="dixin"
                      name="dixin"
                      onClick={filterHandler}
                    />
                    <label htmlFor="dixin">
                      <span>Dixin</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="matam"
                      name="matam"
                      onClick={filterHandler}
                    />
                    <label htmlFor="matam">
                      <span>Matam</span>
                    </label>
                  </div>

                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="beyla"
                      name="beyla"
                      onClick={filterHandler}
                    />
                    <label htmlFor="beyla">
                      <span>Beyla</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="boffa"
                      name="boffa"
                      onClick={filterHandler}
                    />
                    <label htmlFor="boffa">
                      <span>Boffa</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="boké"
                      name="boké"
                      onClick={filterHandler}
                    />
                    <label htmlFor="boké">
                      <span>Boké</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="coyah"
                      name="coyah"
                      onClick={filterHandler}
                    />
                    <label htmlFor="coyah">
                      <span>Coyah</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="dabola"
                      name="dabola"
                      onClick={filterHandler}
                    />
                    <label htmlFor="dabola">
                      <span>Dabola</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="dalaba"
                      name="dalaba"
                      onClick={filterHandler}
                    />
                    <label htmlFor="dalaba">
                      <span>Dalaba</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="dubréka"
                      name="dubréka"
                      onClick={filterHandler}
                    />
                    <label htmlFor="dubréka">
                      <span>Dubréka</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="faranah"
                      name="faranah"
                      onClick={filterHandler}
                    />
                    <label htmlFor="faranah">
                      <span>Faranah</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="fria"
                      name="fria"
                      onClick={filterHandler}
                    />
                    <label htmlFor="fria">
                      <span>Fria</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="dinguiraye"
                      name="dinguiraye"
                      onClick={filterHandler}
                    />
                    <label htmlFor="dinguiraye">
                      <span>Dinguiraye</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="gaoul"
                      name="gaoul"
                      onClick={filterHandler}
                    />
                    <label htmlFor="gaoul">
                      <span>Gaoul</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="kankan"
                      name="kankan"
                      onClick={filterHandler}
                    />
                    <label htmlFor="kankan">
                      <span>Kankan</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="forécariah"
                      name="forécariah"
                      onClick={filterHandler}
                    />
                    <label htmlFor="forécariah">
                      <span>Forécariah</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="kérouané"
                      name="kérouané"
                      onClick={filterHandler}
                    />
                    <label htmlFor="kérouané">
                      <span>Kérouané</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="kindia"
                      name="kindia"
                      onClick={filterHandler}
                    />
                    <label htmlFor="kindia">
                      <span>Kindia</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="guéckédou"
                      name="guéckédou"
                      onClick={filterHandler}
                    />
                    <label htmlFor="guéckédou">
                      <span>Guéckédou</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="koubia"
                      name="koubia"
                      onClick={filterHandler}
                    />
                    <label htmlFor="koubia">
                      <span>Koubia</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="koundara"
                      name="koundara"
                      onClick={filterHandler}
                    />
                    <label htmlFor="koundara">
                      <span>Koundara</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="kissidougou"
                      name="kissidougou"
                      onClick={filterHandler}
                    />
                    <label htmlFor="kissidougou">
                      <span>Kissidougou</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="labé"
                      name="labé"
                      onClick={filterHandler}
                    />
                    <label htmlFor="labé">
                      <span>Labé</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="lélouma"
                      name="lélouma"
                      onClick={filterHandler}
                    />
                    <label htmlFor="lélouma">
                      <span>Lélouma</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="kouroussa"
                      name="kouroussa"
                      onClick={filterHandler}
                    />
                    <label htmlFor="kouroussa">
                      <span>Kouroussa</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="lola"
                      name="lola"
                      onClick={filterHandler}
                    />
                    <label htmlFor="lola">
                      <span>Lola</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="macenta"
                      name="macenta"
                      onClick={filterHandler}
                    />
                    <label htmlFor="macenta">
                      <span>Macenta</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="mali"
                      name="mali"
                      onClick={filterHandler}
                    />
                    <label htmlFor="mali">
                      <span>Mali</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="mamou"
                      name="mamou"
                      onClick={filterHandler}
                    />
                    <label htmlFor="mamou">
                      <span>Mamou</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="pita"
                      name="pita"
                      onClick={filterHandler}
                    />
                    <label htmlFor="pita">
                      <span>Pita</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="mandiana"
                      name="mandiana"
                      onClick={filterHandler}
                    />
                    <label htmlFor="mandiana">
                      <span>Mandiana</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="siguiri"
                      name="siguiri"
                      onClick={filterHandler}
                    />
                    <label htmlFor="siguiri">
                      <span>Siguiri</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="télimélé"
                      name="télimélé"
                      onClick={filterHandler}
                    />
                    <label htmlFor="télimélé">
                      <span>Télimélé</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="n'zérékoré"
                      name="n'zérékoré"
                      onClick={filterHandler}
                    />
                    <label htmlFor="n'zérékoré">
                      <span>N'zérékoré</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="tougué"
                      name="tougué"
                      onClick={filterHandler}
                    />
                    <label htmlFor="tougué">
                      <span>Tougué</span>
                    </label>
                  </div>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      data-filter-type="zone"
                      id="yomou"
                      name="yomou"
                      onClick={filterHandler}
                    />
                    <label htmlFor="yomou">
                      <span>Yomou</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx>{`
          /*.content {
            position: relative;
            max-height: calc(100% - 25px);
            overflow-x: hidden;
          }*/
          .content {
            position: relative;
            max-height: calc(100% - 40px);
            overflow-x: hidden;
            margin-top: 7px;
          }
          .content::-webkit-scrollbar {
            width: 0.3rem;
            -webkit-appearance: none;
          }

          @media screen and (min-width: 1040px) {
            .sectionThree {
              margin-right: 20px;
            }
          }

          @media screen and (max-width: 480px) {
            .sectionOne {
              margin-left: -6px;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default Filter;
