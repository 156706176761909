import React from "react";
import BillboardCard from "./BillboardCard";
import BillboardCardSkeleton from "./BillboardCardSkeleton";

const GridView = (props) => {
  return (
    <>
      <div className="billboards row">
        {
          props.filteredItems.length > 0 &&
            props.filteredItems.map((data, index) => (
              <div key={data.id} className="col-md-6 col-lg-4 col-xl-3">
                <BillboardCard data={data} transitionDuration={0.17 * index} />
              </div>
            ))
          /*: 
          props.click == false &&
              Array(8)
              .fill()
              .map((item, index) => (
                <div key={index} className="col-md-6 col-lg-4 col-xl-3">
                  <BillboardCardSkeleton />
                </div>
              ))*/
        }
      </div>
    </>
  );
};

export default GridView;
