import styles from "./billboards.module.scss";
import { motion } from "framer-motion";
import { useStoreActions, useStoreState } from "easy-peasy";
import UseModal from "../Modal/UseModal";
import { toast } from "react-toastify";
import QuickViewBillboardModal from "../Modal/QuickViewBillboard";
import { BiErrorAlt } from "react-icons/bi";
import { useRef, useState } from "react";
import Link from "next/link";
import slugify from "slugify";

const easing = [0.6, -0.05, 0.01, 0.99];
const animation = (duration) => ({
  initial: { y: 100, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration, eas: easing },
  },
});

const BillboardCard = ({ data, transitionDuration }) => {
  const [ConfModalWrapper, openConfModal, closeConfModal, isConfModalOpen] =
    UseModal();

  const { formatPrice, formatCurrentDate, view } = useStoreState(
    (state) => state.billboards
  );
  const { cartExist, numberOfDays, getDaysArray } = useStoreState(
    (state) => state.bookings
  );
  const { quickView, setGridView } = useStoreActions(
    (actions) => actions.billboards
  );
  const { addToCart, removeCart } = useStoreActions(
    (actions) => actions.bookings
  );

  const [mapView, setMapView] = useState(false);

  const dateMonthsError = () => {
    toast.error(
      <strong
        style={{ display: "block", textAlign: "center", fontSize: "15px" }}
      >
        <BiErrorAlt /> Le nombre de jours entre les deux dates de réservations
        doit être supérieur ou égale à 30
      </strong>,
      {
        position: "top-center",
        autoClose: 5000,
      }
    );
  };

  const dateError = () => {
    toast.error(
      <strong
        style={{ display: "block", textAlign: "center", fontSize: "15px" }}
      >
        <BiErrorAlt /> La date de fin de réservation doit être supérieur à la
        date de début de réservation
      </strong>,
      {
        position: "top-center",
        autoClose: 5000,
      }
    );
  };

  const dateStartAtError = () => {
    toast.error(
      <strong
        style={{ display: "block", textAlign: "center", fontSize: "15px" }}
      >
        <BiErrorAlt /> La date de début de réservation ne peut pas être dans le
        passé.
      </strong>,
      {
        position: "top-center",
        autoClose: 5000,
      }
    );
  };

  const handleClick = (
    id,
    price,
    region,
    image_url,
    category,
    dimension,
    common,
    location_detail,
    user,
    start_at,
    end_date,
    bookings,
    //dayList,
    bookend_by_face,
    faces
  ) => {
    const selectedBillboard = {
      id: id,
      price: price,
      image_url: image_url,
      category: category,
      dimension: dimension,
      region: region,
      common: common,
      location_detail: location_detail,
      user: user,
      start_at: start_at,
      end_date: end_date,
      bookings: bookings,
      //dayList: dayList,
      bookend_by_face: bookend_by_face,
      faces: faces,
    };
    /*const daysInputs = getDaysArray(new Date(selectedBillboard.start_at), new Date(selectedBillboard.end_date));
    console.log('date dans inputs', daysInputs)
    console.log('date déjà prise', dayList)*/

    //closeConfModal()
    if (cartExist(selectedBillboard.id)) {
      removeCart(selectedBillboard.id);
    } /*else if(dayList.includes(new Date("2021-09-25"))){
      alert("cette date est déjà prise")
    }*/ else if (selectedBillboard.start_at < formatCurrentDate()) {
      dateStartAtError();
    } else if (selectedBillboard.start_at >= selectedBillboard.end_date) {
      dateError();
    } else if (
      numberOfDays(
        new Date(selectedBillboard.start_at),
        new Date(selectedBillboard.end_date)
      ) < 30
    ) {
      dateMonthsError();
    } else {
      addToCart(selectedBillboard);
    }
  };

  //scroll
  const myRef = useRef(null);
  const executeScroll = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView(/*{ behavior: 'smooth' }*/);
    }
  };
  const quickViewMaps = (data) => {
    const selectedViewBillboard = {
      id: data.id,
      price: data.price,
      image_url: data.image_url,
      category: data.category,
      dimension: data.dimension,
      region: data.region,
      common: data.common,
      location_detail: data.location_detail,
      description: data.description,
      user: data.user,
      bookings: data.bookings,
      latitude: data.latitude,
      longitude: data.longitude,
      bookend_by_face: data.bookend_by_face,
    };
    setMapView(true);
    openConfModal();
    quickView(selectedViewBillboard);
  };

  const quickViewBillboard = (data) => {
    const selectedViewBillboard = {
      id: data.id,
      price: data.price,
      image_url: data.image_url,
      category: data.category,
      dimension: data.dimension,
      region: data.region,
      common: data.common,
      location_detail: data.location_detail,
      description: data.description,
      user: data.user,
      bookings: data.bookings,
      latitude: data.latitude,
      longitude: data.longitude,
      bookend_by_face: data.bookend_by_face,
    };
    setMapView(false);
    openConfModal();
    quickView(selectedViewBillboard);
  };

  const notAnAdvertiser = () => {
    toast.warning(
      <strong
        style={{ display: "block", textAlign: "center", fontSize: "15px" }}
      >
        Vous devez avoir un profile "Annonceur" pour passer une commande.
      </strong>,
      {
        position: "top-center",
        autoClose: 5000,
        toastId: "invalid-booking-form-error-toast-id",
      }
    );
  };

  const isNotConnected = () => {
    toast.warning(
      <strong
        style={{ display: "block", textAlign: "center", fontSize: "15px" }}
      >
        Vous devez être connecté pour passer une commande.
      </strong>,
      {
        position: "top-center",
        autoClose: 5000,
        toastId: "invalid-login-form-error-toast-id",
      }
    );
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial="initial"
      animate="animate"
      variants={animation(transitionDuration)}
    >
      <div className={styles.boardCard}>
        <div
          onClick={quickViewBillboard.bind(this, data)}
          className={styles.boardImg}
          style={{
            backgroundImage:
              data.image_url !== null ? `url(${data.image_url})` : "",
          }}
        ></div>
        <summary className="card-details">
          <div className={styles.firstLine}>
            <div className={styles.location}>
              {/* <FaMapMarkerAlt color="#fbc216" /> */}
              <span className={styles.icon}>
                <img src="/icons/worldwide.svg" alt="Adresse" />
              </span>
              <div className="d-flex flex-column">
                <div className={styles.common}>{data.common}</div>
                <small>{data.location_detail.substring(0, 25)}...</small>
              </div>
            </div>

            {
              <span
                title={cartExist(data.id) ? "déjà ajouté" : ""}
                className={cartExist(data.id) ? "" : styles.added}
                onClick={() => handleClick(data.id)}
                style={{ color: "#fbc216" }}
              >
                {cartExist(data.id) && "✔ Ajouté"}
              </span>
            }
          </div>
          <div className={styles.price}>
            {/* <FaClipboardList color="#fbc216" /> */}
            <span className={styles.icon}>
              <img src="/icons/price.svg" alt="Prix" />
            </span>
            <div>
              <div>
                <span className="fw-600">{formatPrice(data.price)}</span>
                <small> / mois</small>
              </div>
            </div>
          </div>
        </summary>
        <footer>
          <Link
            href={`/panneaux/${data.id}-${slugify(data.location_detail, {
              lower: true,
            })}`}
          >
            <a className={styles.detailsBtn}>
              <small>En savoir plus...</small>
            </a>
          </Link>

          <button
            onClick={quickViewMaps.bind(this, data)}
            className={`animate-child ${styles.viewInMapLink}`}
          >
            <span className="to-animate">
              <img src="/icons/ic_map_24px.svg" alt="Carte" />
            </span>
            <small>Sur la carte</small>
          </button>
        </footer>
      </div>

      <QuickViewBillboardModal
        ModalWrapper={ConfModalWrapper}
        close={closeConfModal}
        isOpen={isConfModalOpen}
        handleClick={handleClick}
        notAnAdvertiser={notAnAdvertiser}
        isNotConnected={isNotConnected}
        mapView={mapView}
        myRef={myRef}
        executeScroll={executeScroll}
      />
    </motion.div>
  );
};

export default BillboardCard;
