const formatDateUtils = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = date.toString().split(" ")[1];
  const year = date.toString().split(" ")[3];

  const hour = String(date.getUTCHours()).padStart(2, "0");
  const min = String(date.getUTCMinutes()).padStart(2, "0");
  const second = String(date.getUTCSeconds()).padStart(2, "0");

  return `${day} ${month} ${year} à ${hour}:${min}:${second}`;
};
export default formatDateUtils;

export const shareOnFB = ({ url, message = "" }) => {
  const w = 600;
  const h = 300;
  const left = window.innerWidth / 2 - w / 2;
  const top = window.innerHeight / 2 - h / 2;

  window.open(
    `https://facebook.com/sharer/sharer.php?u=${encodeURI(url)}&t=${message}`,
    "Facebook",
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${h},width=${w},top=${top},left=${left}`
  );
  return false;
};

export const shareOnTwitter = ({
  url,
  message = "Découvrez dès maintenant nos panneaux publicitaires 👇.\n\n",
}) => {
  const w = 600;
  const h = 300;
  const left = window.innerWidth / 2 - w / 2;
  const top = window.innerHeight / 2 - h / 2;

  window.open(
    `https://twitter.com/intent/tweet?url=${encodeURI(
      url
    )}&via=regitorr&text=${message}&hashtags=regitorr`,
    "Twitter",
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${h},width=${w},top=${top},left=${left}`
  );
  return false;
};

export const shareOnLinkedIn = ({ url }) => {
  const w = 600;
  const h = 300;
  const left = window.innerWidth / 2 - w / 2;
  const top = window.innerHeight / 2 - h / 2;

  window.open(
    `https://linkedin.com/shareArticle?mini=true&url=${encodeURI(url)}`,
    "LinkedIn",
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${h},width=${w},top=${top},left=${left}`
  );
  return false;
};

export function getFullUrl(req, fallback) {
  //server side request object(req)
  if (req) {
    // const h = req.getHeaders();
    return req.protocol + "://" + req.host + req.originalUrl;
  } //making sure we are on the client side
  else if (!(typeof window === "undefined")) {
    return window.location.href;
  } else {
    return fallback;
  }
}

export function getFilteringCombos(...args) {
  args = args.filter((arg) => arg.items.length);
  const r = [],
    max = args.length - 1;

  const helper = (arr, i, groups) => {
    for (let j = 0, l = groups[i].items.length; j < l; j++) {
      const a = arr.slice(0); // clone arr
      a.push({ [`${groups[i].type}`]: groups[i].items[j] });
      if (i === max) {
        let obj = {};
        a.forEach((elt) => {
          obj = { ...obj, ...elt };
        });
        r.push(obj);
      } else helper(a, i + 1, groups);
    }
  };
  if (args.length) helper([], 0, args);
  return r;
}
